import { getExecute } from '@/utils/dialogExecute'

export const rules = {
  companyName: [
    { required: true, message: '请输入企业名称', trigger: 'change' }
  ],
  cover: [
    { required: false, message: '请企业封面', trigger: 'change' }
  ],
  scope: [
    { required: true, message: '请输入企业经营范围', trigger: 'change' }
  ],
  mobile: [
    { required: true, message: '请输入企业电话', trigger: 'change' }
  ],
  location: [
    { required: true, message: '请输入企业地址', trigger: 'change' }
  ],
  logo	: [
    { required: false, message: '请上传企业logo', trigger: 'change' }
  ],
  description: [
    { required: true, message: '请输入企业简介', trigger: 'change' }
  ]
}

export function resetCompany() {
  return {
    companyName: '',
    cover: '', // 企业封面
    scope: '', // 经营范围
    mobile: '', // 企业电话
    location: '', // 企业地址
    logo: '', // 企业logo
    description: ''
  }
}

const singleVue = {
  addOrEditCompany: false,
  showImg: false
}

const strategyMap = {
  addOrEditCompany: () => import('./components/addOrEditCompany.vue'), // 日志显示
  showImg: () => import('./components/showImg.vue') // 日志显示
}

export const execute = getExecute(singleVue, strategyMap)
