<template>
  <el-dialog
      :title="title"
      :visible.sync="createDialogVisible"
      width="800px"
      :close-on-click-modal="false"
      :append-to-body="true"
      v-drag-dialog
      @close="close">
    <div class="pageContainer">
      <el-form label-position="right"
               :model="formData"
               :rules="rules"
               ref="CompanyForm"
               label-width="100px"
               element-loading-background="rgba(0, 0, 0, 0.8)">
        <el-row>
          <el-col :span="12">
            <el-form-item label="企业名称" prop="companyName">
              <el-input v-model="formData.companyName" placeholder="请输入企业名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="企业地址" prop="location">
              <el-input v-model="formData.location" placeholder="请输入企业地址"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="企业经营范围" prop="scope">
              <el-input v-model="formData.scope" placeholder="请输入企业经营范围"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="企业电话" prop="mobile">
              <el-input v-model="formData.mobile" placeholder="请输入企业电话"></el-input>
<!--              <file-upload-btn @uploadSuccess="handleUploadSuccess"></file-upload-btn>-->
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="企业logo" prop="logo">
              <img :src="formData.logo" @click="showImg(formData.logo, '企业logo')" class="smallImg" alt="">
              <file-upload-btn v-if="createDialogVisible" @uploadSuccess="handleUploadSuccessLogo"></file-upload-btn>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="企业封面" prop="cover">
              <img :src="formData.cover" @click="showImg(formData.cover, '企业封面')" class="smallImg" alt="">
              <file-upload-btn v-if="createDialogVisible" @uploadSuccess="handleUploadSuccessCover"></file-upload-btn>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="企业简介" prop="description">
            <el-input type="textarea" :maxlength="1000"
                      :rows="8" v-model="formData.description" placeholder="请输入企业简介"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="企业详情" prop="content">
            <wang-editor v-if="createDialogVisible" v-model="formData.content"></wang-editor>
          </el-form-item>
        </el-row>
      </el-form>
      <el-row type="flex" justify="center">
        <el-button type="primary" @click="trySubmit" :loading="addingLoading">确认提交</el-button>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import FileUploadBtn from 'modules/components/fileUploadBtn/index.vue'
import WangEditor from 'modules/components/WangEditor/index.vue'
import { rules, resetCompany, execute } from '../option'

export default {
  name: 'addOrEditCompany',
  components: {
    FileUploadBtn,
    WangEditor
  },
  data() {
    return {
      optType: 'add',
      createDialogVisible: false,
      callback: null,
      formData: resetCompany(),
      rules,
      addingLoading: false
    }
  },
  computed: {
    title() {
      return this.optType === 'add' ? '新增企业' : '编辑企业'
    }
  },
  methods: {
    ...mapActions(['saveCompany', 'getCompanyById', 'updateCompany']),
    show(options) {
      this.optType = options.optType
      this.callback = options.callback
      if (this.optType === 'edit') {
        this.tryGetCompanyById(options.detail.id)
        // this.formData = options.detail
      } else {
        this.formData = resetCompany()
      }
      this.createDialogVisible = true
      this.$nextTick(() => {
        this.$refs.CompanyForm.clearValidate()
      })
    },
    close() {
      this.createDialogVisible = false
    },
    showImg(imgUrl, title) {
      execute('showImg', {
        imgUrl,
        title
      })
    },
    handleUploadSuccessLogo(url) {
      this.formData.logo = url
    },
    handleUploadSuccessCover(url) {
      this.formData.cover = url
    },
    tryGetCompanyById(id) {
      this.getCompanyById(id).then(res => {
        this.formData = res || resetCompany()
      })
    },
    tryUpdateCompany() {
      this.addingLoading = true
      this.updateCompany(this.formData).then(() => {
        this.$message.success('操作成功')
        this.callback && this.callback()
        this.close()
      }).finally(() => {
        this.addingLoading = false
      })
    },
    trySubmit() {
      if (this.addingLoading) {
        return
      }
      this.$refs.CompanyForm.validate(valid => {
        if(valid) {
          const api = this.optType === 'add' ? this.trySaveCompany : this.tryUpdateCompany
          api()
        }
      })
    },
    trySaveCompany() {
      this.addingLoading = true
      this.saveCompany(this.formData).then(() => {
        this.$message.success('提交成功')
        this.resetCompany()
        this.callback && this.callback()
        this.close()
      }).finally(() => {
        this.addingLoading = false
      })
    },
    resetCompany() {
      this.formData = resetCompany()
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../scss/elementVar";

.pageContainer {
  padding: 20px;
}
.tagWrap {
  margin-bottom: 20px;
}
.uploadBtn {
  padding: 30px 0;
  width: 150px;
  height: 100px;
  box-sizing: border-box;
  text-align: center;
  color: $--color-info;
  border: 2px dashed $--color-info;
  opacity: 0.7;
  border-radius: 4px;
  transition: all linear 100ms;
}
.uploadBtn:hover {
  opacity: 1;
}
.uploadBtnIcon {
  font-weight: bold;
  font-size: 30px;
}
.uploadBtnText {
  line-height: 10px;
  font-size: 14px;
}
.imgContainer {
  float: left;
  margin: 0 10px 10px 0;
}
.smallImg{
  width: 40px;
}
</style>
